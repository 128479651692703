import React from "react"
import { useRoutes } from "react-router-dom"
import NotFound from '../pages/NotFound'
import Mb from '../components/Mb/Mb'
import Inactive from "../pages/Inactive"

const RenderRoutes = () => {

    const RouteObject = [
        {
            path: "/:slug",
            exact: true,
            element: (<Mb />),
            children: []
        },
        {
            path: "/inactive",
            exact: true,
            element: (<Inactive />),
            children: []
        },
        { path: "*", element: (<NotFound /> )}
    ];

    return useRoutes(RouteObject, );
}

export default RenderRoutes
