/**
 * API CONFIG
 */
export const API_ENTRYPOINT = process.env.REACT_APP_API_ENTRYPOINT
export const API_PREFIX = process.env.REACT_APP_API_PREFIX
export const MERCURE_ENTRYPOINT = process.env.REACT_APP_MERCURE_ENTRYPOINT

/**
 * AUTH
 */
export const API_REFRESH_TOKEN_PATH = '/api/auth/refresh_token'
export const API_LOGIN_PATH = '/api/auth/authentication_token'
export const API_REGISTER_PATH = '/auth/register'

/**
 * ADMIN CONFIG
 */
export const ADMIN_PATH = process.env.REACT_APP_ADMIN_PATH

/**
 * LEGAL CONFIG
 */
export const USE_COOKIES = false
export const COOKIE_PAGE_URL = 'https://example.com/legal/politica-cookies'

/**
 * APP CONFIG
 */
export const USE_AOS = true
export const USE_TOAST = true