import React, { useCallback, useEffect, useState } from 'react'
import logo from '../../logo-echevarne-blanco.svg'
import dateFormat from "dateformat"
import { MinusIcon } from '@heroicons/react/outline'
import useFetch from '../../hooks/useFetch'
import { useParams } from 'react-router-dom'

const INACTIVITY_TIME = 120;

const Mb = props => {

    const { slug } = useParams()

    if(!slug) window.location = '/'

    const {
        fetch
    } = useFetch()

    const [date, setDate] = useState();
    const [turns, setTurns] = useState([]);
    const [currentTurn, setCurrentTurn] = useState();
    const [newTurn, setNewTurn] = useState();
    const [loading, setLoading] = useState(true);
    const [countInactivity, setCountInactivity] = useState(INACTIVITY_TIME)

    const getTurns = useCallback(
        async () => {
            let turns = []
            await fetch(`/api/turns/?service.institution.slug=${slug}&groups[]=next:read&itemsPerPage=8&createdAt[after]=` + dateFormat(new Date(), 'yyyy-mm-dd') + '&startAt[exists]=true&order[startAt]=desc')
                .then((res) => res.json()
                .then(async retrieved => {
                    turns = await retrieved["hydra:member"]
                }))
                .catch((error) => {})
            setCurrentTurn(turns[0])
            await setTurns(turns.filter(e => e?.['@id'] !== turns?.[0]?.['@id']))
            await setLoading(false)
        },
    [slug])

    useEffect(() => {
        const timeout = setInterval(() => getTurns(), 20000);
        getTurns();
        return () => clearInterval(timeout)
    }, [])

    useEffect(() => {
        setTimeout(function() {
            setDate(new Date())
        }, 10000)
    }, [date])

    useEffect(() => {
        if (turns[0]) {
            if (newTurn ? newTurn.uuid !== turns[0].uuid : true) {
                setNewTurn(turns[0])
            }
        }
    }, [turns])

    useEffect(() => {
        console.info(`Inactividad ${countInactivity}...`)
        if (countInactivity === 0) {
            window.location = '/inactive';
        }
        if (countInactivity < 0) {
            window.location.reload();
        }
    }, [countInactivity])

    useEffect(() => {
        const interval = setInterval(() => setCountInactivity(countInactivity => countInactivity - 1), 1000)
        return () => clearInterval(interval)
    }, [])

    return (
        <div className="w-sceen h-screen flex flex-col">

            <div className="h-28 px-10 py-8 w-full bg-black">
                <div className="h-full flex justify-between">
                    <div className="h-full">
                        <img 
                            src={logo}
                            alt="" 
                            className="h-full text-black"
                        />
                    </div>
                    <div className="hidden lg:block text-5xl text-white">
                        {dateFormat(date, 'H:MM')}
                    </div>
                </div>
            </div>

            {turns.length === 0 && !currentTurn && !loading
                ? (
                    <div className="py-10 text-5xl flex-none flex justify-center font-semibold">
                        Nada que mostrar
                    </div>
                ) : (
                <div className="w-full flex-grow overflow-hidden">
                    <div className="h-full w-full flex flex-col">
                        <div className="w-full flex-none bg-gray-100">
                            <div className="h-full py-5 gap-4 px-8 flex flex-col items-center justify-center">
                                <div className="text-5xl flex-none font-semibold">
                                    Turno actual
                                </div>
                                <div className='w-full sm:max-w-md'>
                                    {currentTurn && (
                                        <div
                                            className="p-3 flex bg-white rounded-xl shadow-lg"
                                        >
                                            <div className="flex-grow">
                                                <div className="w-full px-4 text-xl xl:text-2xl 2xl:text-3xl h-full text-gray-800 gap-2 flex items-center justify-evenly">
                                                    <div className="font-bold">
                                                        {currentTurn.service.institution.uniqueCola ? currentTurn.uniqueCode : currentTurn.code}
                                                    </div>
                                                    <div className="font-bold">
                                                        <MinusIcon className="transform rotate-90 w-10 h-10"/>
                                                    </div>
                                                    <div className="font-bold">
                                                        {currentTurn.desk.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    
                        <div className="flex-grow overflow-hidden">
                            <div className="gap-4 px-8 w-full h-full flex flex-col items-center overflow-y-auto">
                                <div className="w-full pt-5 prose prose-xl flex justify-center">
                                    <h2 className="font-semibold">Turnos anteriores</h2>
                                </div>
                                <div className="w-full sm:max-w-md gap-4 pb-7 grid grid-rows-8">
                                    {turns.map((turn,idx) => (
                                        <div
                                            key={idx} 
                                            className="p-3 flex bg-white rounded-xl shadow-lg"
                                        >
                                            <div className="flex-grow">
                                                <div className="w-full px-4 text-xl xl:text-2xl 2xl:text-3xl h-full text-gray-800 gap-2 flex items-center justify-evenly">
                                                    <div className="font-bold">
                                                        {turn.service.institution.uniqueCola ? turn.uniqueCode : turn.code}
                                                    </div>
                                                    <div className="font-bold">
                                                        <MinusIcon className="transform rotate-90 w-10 h-10"/>
                                                    </div>
                                                    <div className="font-bold">
                                                        {turn.desk.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </div>
    )
}

export default Mb
