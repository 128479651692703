import { useContext, useEffect, useState } from 'react'
import { API_ENTRYPOINT, MERCURE_ENTRYPOINT } from '../config'
import get from "lodash/get"
import has from "lodash/has"
import mapValues from "lodash/mapValues"

const MIME_TYPE = "application/ld+json"

const useFetch = props => {

  const [event, setEvent] = useState()

  const fetch = (id, options = {}, expireCheck = true) => {

    if ("undefined" === typeof options.headers) options.headers = new Headers()
    if (null === options.headers.get("Accept"))
      options.headers.set("Accept", MIME_TYPE)
    if (
      "undefined" !== options.body &&
      !(options.body instanceof FormData) &&
      null === options.headers.get("Content-Type")
    )
    options.headers.set("Content-Type", MIME_TYPE)
    localStorage.getItem('token') && options.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`)
    return global.fetch(new URL(id, API_ENTRYPOINT + '/api/'),
    {
      method: options.method,
      body: options.body,
      headers: options.headers,
      // credentials: 'include'
    })
    .then(async (response) => {
      if (await response.ok) return await response
      // if(response.status === 404) return await response
      if (response.status === 401) {
        // if (await refreshToken()){
        //   return await fetch(id, options = {}, location)
        // }else{
        //   window.dispatchEvent(new Event('logout'))
        // }
        await response.json().then(async retrieved => {
          throw new Error(await retrieved['hydra:description'] || "Unauthorized")
        })
      }
      if (response.status === 403) {
        await response.json().then(async retrieved => {
          throw new Error(await retrieved['hydra:description'] || "An error occurred.")
        })
      }

      if (response.status === 422) {
        await response.json().then(async retrieved => {
          const error = await retrieved["hydra:description"] || retrieved["hydra:title"] || "An error occurred."
          
          if (!await retrieved.violations){
            throw new Error(JSON.stringify(await retrieved['hydra:description'] || "An error occurred."))
          }

          let errors = { _error: error }
          await retrieved.violations.forEach((violation) =>
            errors[violation.propertyPath]
              ? (errors[violation.propertyPath] +=
                  "\n" + errors[violation.propertyPath])
              : (errors[violation.propertyPath] = violation.message)
          )

          throw new Error(await JSON.stringify(errors))

        })
      }

      await response.json().then(async retrieved => {
        throw new Error(await retrieved['hydra:description'] || "An error occurred.")
      })

    })
      
  }

  const subscribe = (url, topics) => {
    // topics.forEach((topic) =>
    //   url.searchParams.append("topic", new URL(topic, API_ENTRYPOINT))
    // )
    return new EventSource('https://apicolascentral.laboratorioechevarne.com/.well-known/mercure?topic=http://colascentral.laboratorioechevarne.com:9123/api/turns/&topic=http://colascentral.laboratorioechevarne.com:9123/api/institutions/&topic=http://colascentral.laboratorioechevarne.com:9123/api/services/')
  }
  
  const normalize = (data) => {
    if (has(data, "hydra:member")) {
      // Normalize items in collections
      data["hydra:member"] = data["hydra:member"].map((item) => normalize(item))
  
      return data
    }
  
    // Flatten nested documents
    return mapValues(data, (value) =>
      Array.isArray(value)
        ? value.map((v) => get(v, "@id", v))
        : get(value, "@id", value)
    )
  }
  
  const extractHubURL = (response) => {
    const linkHeader = response.headers.get("Link") ?? `<http://colascentral.laboratorioechevarne.com:9123/api/docs.jsonld> rel="http://www.w3.org/ns/hydra/core#apiDocumentation",<http://colascentral.laboratorioechevarne.com/.well-known/mercure> rel="mercure"`
    if (!linkHeader) return null
  
    const matches = linkHeader.match(
      /<([^>]+)>\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
    )
  
    return matches && matches[1] ? new URL(matches[1], API_ENTRYPOINT) : null
  }

  useEffect(() => {
      if (event) return () => event.close()
  }, [event])

  return {
    fetch,
    subscribe,
    extractHubURL,
    setEvent,
    normalize
  }
}

useFetch.propTypes = {

}

export default useFetch
