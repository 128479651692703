import React from 'react'
import Routes from './routes/Routes'

function App() {

  return (
    <Routes />
  );

}

export default App;
