import React from 'react'

const NotFound = props => (
    <section className="flex items-center h-full p-16">
        <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
            <div className="max-w-md text-center">
                <img className='my-10' src="/images/logo-echevarne.svg" alt="" />
                <h2 className="mb-8 font-extrabold text-9xl">
                    <span className="sr-only">Error</span>404
                </h2>
                <p className="text-2xl font-semibold md:text-3xl">Lo sentimos, no pudimos encontrar esta página.</p>
                {/* <p className="mt-4 mb-8 dark:text-coolGray-400">Pero no te preocupes, puedes encontrar muchas otras cosas en nuestra página de inicio.</p> */}
            </div>
        </div>
    </section>
)

export default NotFound
